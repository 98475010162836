var render = function () {
  var _vm$user, _vm$user2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12"
    }
  }, [_c('b-card', {
    staticClass: "text-center"
  }, [_c('h1', [_vm._v("How To Create an API Key - Video Tutorial")]), _c('b-card-text', [_vm._v(" Please watch the following video to create a Google API key. Without this, the software won't work. ")]), _c('br'), _c('br'), _c('div', [_c('iframe', {
    attrs: {
      "width": "560",
      "height": "315",
      "src": "https://www.youtube.com/embed/S6F-YuQhgM4",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
      "allowfullscreen": ""
    }
  })]), _c('br'), _c('br'), _c('h1', [_vm._v(" How To Enable Google Cloud Billing ")]), _c('b-card-text', [_vm._v(" Necessary step, no costs involved if you don't spam ")]), _c('br'), _c('br'), _c('div', [_c('iframe', {
    attrs: {
      "width": "560",
      "height": "315",
      "src": "https://www.youtube.com/embed/lDexLa5Qx_s",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
      "allowfullscreen": ""
    }
  })]), _c('br'), _c('br'), _c('div', [_c('h1', [_vm._v(" Google API Key ")]), _c('validation-observer', {
    ref: "apiKeyValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "api_key",
      "rules": "required",
      "vid": "vi-apikey"
    }
  }, [(_vm$user = _vm.user) !== null && _vm$user !== void 0 && _vm$user.api_key ? _c('b-form-input', {
    attrs: {
      "id": "apikey",
      "type": "text",
      "name": "api_key",
      "disabled": _vm.isEditApiKey,
      "placeholder": "*******************************"
    },
    model: {
      value: _vm.apikey,
      callback: function callback($$v) {
        _vm.apikey = $$v;
      },
      expression: "apikey"
    }
  }) : _c('b-form-input', {
    attrs: {
      "id": "apikey",
      "type": "text",
      "name": "api_key",
      "placeholder": "ENTER API KEY"
    },
    model: {
      value: _vm.apikey,
      callback: function callback($$v) {
        _vm.apikey = $$v;
      },
      expression: "apikey"
    }
  })], 1), _c('br'), _c('br'), _vm.isApiKeyFormSubmitLoading ? [_c('b-button', {
    attrs: {
      "variant": "secondary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : (_vm$user2 = _vm.user) !== null && _vm$user2 !== void 0 && _vm$user2.api_key ? [_vm.isEditApiKey ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.changeEditStatus
    }
  }, [_vm._v(" Edit ")]) : _vm._e(), !_vm.isEditApiKey ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "submit",
      "variant": "warning",
      "disabled": _vm.apiKeyUpdateButton
    }
  }, [_vm._v(" Update API Key ")]) : _vm._e(), _c('br'), _c('br'), !_vm.isEditApiKey ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.changeEditStatus
    }
  }, [_vm._v(" Cancel Update ")]) : _vm._e()] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "submit",
      "variant": "secondary",
      "disabled": _vm.apiKeyUpdateButton
    }
  }, [_vm._v(" Save API Key ")])]], 2)], 1)], 1), _c('br')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }